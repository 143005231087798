body {
  height: 100%;
  width: 100%;
  margin: 0;
  overflow-x: hidden;
}
.btn_green_yellow, .btn_yellow_green:hover {
  cursor: pointer;
  background-color: #055837;
  color: #FFCC01;
}

.btn_yellow_green, .btn_green_yellow:hover{
  cursor: pointer;
  background-color: #FFCC01;
  color: #055837;
}



.opacity-low{
  opacity: 0.6;
}


.opacity-low:hover{
  cursor: not-allowed !important;
}

.custom_blue_link,.custom_blue_link:hover{
  cursor: pointer;
  color: blue;
  text-decoration: underline;
}



/* 
:focus {
  outline: none; 
}

:focus {
  outline: 2px solid #ffcc01; 
  outline-offset: 2px; 
} */


/* fixing the Labels */

label{
  margin-bottom: 2px;
}

.zoom-in-out-element {
  /* animation: zoom-in-zoom-out 1s ease infinite; */
}

@keyframes zoom-in-zoom-out {
  0% {
    scale: 100%;
  }
  50% {
    scale: 110%;
  }
  100% {
    scale: 100%;
  }
}